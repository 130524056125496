/* eslint-disable no-unused-vars */
import { getTituloReceberPagined } from 'core/services/FIN/tituloReceber';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { DatePicker, GridView, Panel } from 'ui/components';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';

const TitulosStep = forwardRef(
  ({ data, setData, setLoading, onOpenTransaction, findTransaction }) => {
    const gridView = useRef();

    const [filters, setFilters] = useState({});

    const onOpenTituloReceber = async (e, datasource) => {
      setLoading(true);

      const gridItem = datasource.find((el) => el === e);

      const tituloReceberTransaction = await findTransaction(
        TransacationCodes.TituloReceber
      );

      tituloReceberTransaction.registryKey = gridItem.nrSeqTituloReceber;

      onOpenTransaction(
        tituloReceberTransaction,
        true,
        undefined,
        undefined,
        true
      );

      setLoading(false);
    };

    const columns = [
      { key: 'cdTituloPagar', title: 'Compromisso' },
      { key: 'nrTit', title: 'Nr. Tit' },
      { key: 'nrOrdem', title: 'Ordem', visible: false },
      {
        key: 'fornecedor.noFornecedor',
        title: 'Fornecedor',
        sortKey: 'noFornecedor',
        visible: false,
      },
      {
        key: 'tipoDespesa.noTipoDespesa',
        title: 'Tipo Despesa',
        sortKey: 'noTipoDespesa',
        visible: false,
      },
      {
        key: 'planoConta.noPlanoConta',
        title: 'Plano De Conta',
        visible: false,
      },
      {
        key: 'noVeiculos',
        title: 'Veículos',
        visible: false,
      },
      {
        key: 'formaPagamento.noFormaPagamento',
        title: 'Forma Pagamento',
        sortKey: 'noFormaPagamento',
      },
      {
        key: 'vlrTitulo',
        title: 'Valor',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'vlrPago',
        title: 'Pago',
        format: GridView.DataTypes.Decimal,
        sortable: false,
      },
      {
        key: 'vlrSaldoTit',
        title: 'Saldo',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'vlrDesconto',
        title: 'Desconto',
        visible: false,
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'vlrJuroPago',
        title: 'Juro',
        visible: false,
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'vlrMultaPaga',
        title: 'Multa',
        visible: false,
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'dtVencimento',
        title: 'Vencimento',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'dtPagamento',
        title: 'Pagamento',
        format: GridView.DataTypes.Date,
        visible: false,
      },
      {
        key: 'nrParcela',
        title: 'Parcelas Pagas',
        format: GridView.DataTypes.Integer,
        visible: false,
      },
      {
        key: 'nrSeqTituloPagar',
        type: GridView.ColumnTypes.Button,
        // onClick: (e) => onOpenPagamento(e),
        theme: Theme.Primary,
        icon: 'cash-register',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Quitar Titulo',
        tooltipDirection: 'bottom',
        title: 'Quitar',
        visible: false,
      },
      {
        key: 'nrSeqTituloReceber',
        title: 'Abrir',
        type: GridView.ColumnTypes.Button,
        onClick: (e, dataSource) => onOpenTituloReceber(e, dataSource),
        theme: Theme.Info,
        icon: 'folder-open',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Abrir Demanda',
        tooltipDirection: 'bottom',
      },
    ];

    const getTitulos = async (params = null) => {
      setLoading(true);

      const vencimento = new Date();
      vencimento.setDate(vencimento.getDate() - 1);

      let obj = {
        tipoTituloReceber: 'A',
        tipoFormaRecebimento: 'P',
        pesquisaPlanoConta: 'N',
        pesquisarVeiculo: 'N',
        tipoBuscaVendedor: 'P',
        dtVencimentoAt: vencimento.toJSON().slice(0, 10),
      };

      if (params !== null) {
        obj = { ...obj, ...params };
      }

      const { data: titulos, pagination } = await getTituloReceberPagined(obj);

      gridView?.current?.setDataSource(titulos, pagination);

      setLoading(false);
    };

    useEffect(() => {
      getTitulos();
    }, []);

    const onColumnSort = (sortBy) => {
      const filtros = { ...filters, sortBy };

      setFilters(filtros);

      getTitulos(filtros);
    };

    const onPageSizeChange = (totalByPage) => {
      const filtros = { ...filters, totalByPage };

      setFilters(filtros);

      getTitulos(filters);
    };

    const onPageChange = (page) => {
      const filtros = { ...filters, page };

      setFilters(filtros);

      getTitulos(filtros);
    };

    return (
      <div className='row'>
        <div className='col'>
          <Panel collapsable={false} visible>
            <Panel.Header title='Títulos Pendentes' theme={Theme.Primary} />
            <Panel.Body>
              {/* <div className='row mb-3'>
              <div className='col'>
                <DatePicker
                  label='De'
                  text={filters.noAreaChamado}
                  onChange={(dtInicial) => {
                    setFilters({ ...filters, dtSolicitacaoInicial: dtInicial });
                  }}
                />
              </div>
              <div className='col'>
                <DatePicker
                  label='Até'
                  text={filters.noAreaChamado}
                  onChange={(dtFinal) => {
                    setFilters({ ...filters, dtSolicitacaoFinal: dtFinal });
                  }}
                />
              </div>
            </div> */}
              <div className='row'>
                <div className='col'>
                  <GridView
                    ref={gridView}
                    dataColumns={columns}
                    // showSelectSizes={false}
                    // showPagination={false}
                    onColumnSort={onColumnSort}
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onPageChange}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    );
  }
);

export default TitulosStep;
