/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { CSDSelPage, Steps } from 'ui/components';
import {
  GridviewColumns,
  Message,
  Page,
  RowDoubleClick,
} from 'ui/Helpers/interfaces';
import { ColumnTypes, ColumnDataTypes } from 'ui/Helpers/enums';
import { getTituloReceberPagined } from 'core/services/FIN/tituloReceber';
import { getDemandasDevolvidasPaginated } from 'core/services/HELP/demanda';
import HomeStep from './Steps/homeStep';
import DemandasStep from './Steps/demandaStep';
import TitulosStep from './Steps/titulosStep';

export default function DashboardPendencia({
  transaction,
  onOpenPage,
  isActive,
  onOpenTransaction,
  findTransaction,
}: Page) {
  const homeStepRef: any = useRef();
  const demandasStepRef: any = useRef();
  const titulosStepRef: any = useRef();

  const [filtros, setFiltros] = useState<any>({});
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  function getData() {
    return data;
  }

  const StepHome = () => (
    <div>
      <HomeStep
        ref={homeStepRef}
        // @ts-expect-error
        data={data}
        setData={setData}
        setLoading={setLoading}
      />
    </div>
  );

  const StepDemandas = () => (
    <div>
      <DemandasStep
        ref={demandasStepRef}
        // @ts-expect-error
        data={data}
        setData={setData}
        setLoading={setLoading}
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
      />
    </div>
  );

  const StepTitulos = () => (
    <div>
      <TitulosStep
        ref={titulosStepRef}
        // @ts-expect-error
        data={data}
        setData={setData}
        setLoading={setLoading}
        onOpenTransaction={onOpenTransaction}
        findTransaction={findTransaction}
      />
    </div>
  );

  const step1Home = React.createElement(StepHome);
  const step2Demandas = React.createElement(StepDemandas);
  const step3Titulos = React.createElement(StepTitulos);

  const steps = [
    {
      index: 1,
      title: 'Home',
      visible: true,
      content: () => step1Home,
    },
    {
      index: 2,
      title: 'Demandas Devolvidas',
      visible: true,
      content: () => step2Demandas,
    },
    {
      index: 3,
      title: 'Títulos',
      visible: true,
      content: () => step3Titulos,
    },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Dashboard Cliente'
      loading={loading}
      // onSearch={search}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <Steps
          propriedade={1}
          steps={steps}
          theme={Theme.Primary}
          align='vertical'
          // registryKey={}
          data={data}
          setData={setData}
          getData={getData}
        />
      </div>
    </CSDSelPage>
  );
}
