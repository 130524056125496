/* eslint-disable no-unused-vars */
import { getGraficoDemandasDevolvidaInfo } from 'core/services/HELP';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { CSDBarGraphic, Panel } from 'ui/components';
import { Theme } from 'ui/Helpers/utils';

const HomeStep = forwardRef(({ data, setData, setLoading }) => {
  const graficoDemandasRef = useRef();

  const [graficoData, setGraficoData] = useState({});

  const getGraficoData = async () => {
    setLoading(true);

    const { value: demandas } = await getGraficoDemandasDevolvidaInfo();

    setGraficoData({ demandas });

    setLoading(false);
  };

  useEffect(() => {
    getGraficoData();
  }, []);

  const optionsBarGraphicDemandas = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { formattedValue } = tooltipItem;

            return `Valor: ${formattedValue}`;
          },
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  };

  useEffect(() => {
    if (Object.keys(graficoData).length > 0) {
      const labels = graficoData.demandas?.map((p) => p.noMes);

      const demandasDevolvidasData = graficoData.demandas?.map(
        (item) => item.qtdDemandas
      );

      graficoDemandasRef?.current?.setDataSource({
        labels,
        datasets: [
          {
            label: 'Demandas Devolvidas',
            data: demandasDevolvidasData,
            backgroundColor: 'rgba(220, 226, 35, 0.8)',
          },
        ],
      });

      // graficoDadosRef?.current?.setDataSource({
      //   labels,
      //   datasets: [
      //     {
      //       label: 'Dados',
      //       data: dadosData,
      //       backgroundColor: 'rgba(27, 54, 222, 0.8)',
      //     },
      //   ],
      // });

      // graficoSmsRef?.current?.setDataSource({
      //   labels,
      //   datasets: [
      //     {
      //       label: 'SMS',
      //       data: smsData,
      //       backgroundColor: 'rgba(46, 198, 33, 0.8)',
      //     },
      //   ],
      // });
    }
  }, [graficoData]);

  return (
    <div className='row'>
      <div className='col'>
        <Panel>
          <Panel.Header title='Home' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col'>
                <CSDBarGraphic
                  ref={graficoDemandasRef}
                  options={optionsBarGraphicDemandas}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
});

export default HomeStep;
