/* eslint-disable no-unused-vars */
import { getDemandasDevolvidasPaginated } from 'core/services/HELP';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { DatePicker, GridView, Panel } from 'ui/components';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';

const DemandaStep = forwardRef(
  ({ data, setData, setLoading, onOpenTransaction, findTransaction }) => {
    const gridView = useRef();

    const [filters, setFilters] = useState({});

    const onOpenDemanda = async (e, datasource) => {
      setLoading(true);

      const gridItem = datasource.find((el) => el === e);

      const demandaTransaction = await findTransaction(
        TransacationCodes.Demanda
      );

      demandaTransaction.registryKey = gridItem.nrSeqDemanda;

      onOpenTransaction(demandaTransaction, true, undefined, undefined, true);

      setLoading(false);
    };

    const columns = [
      { key: 'nrSeqDemanda', title: 'Contrato', sortable: false },
      { key: 'noEmpresa', title: 'Empresa', visible: false, sortable: false },
      {
        key: 'operadoraDestino.noOperadora',
        title: 'Operadora',
        sortable: false,
      },
      {
        key: 'tipoDemanda.noTipoDemanda',
        title: 'Tipo',
        sortable: false,
      },
      {
        key: 'noCentroDeCusto',
        title: 'Centro Custo',
        visible: false,
        sortable: false,
      },
      { key: 'chip.linhaFormatada', title: 'Linha' },
      { key: 'noCliente', title: 'Cliente', sortable: false },
      {
        key: 'noUsuarioSolicitacao',
        title: 'Usu. Sol.',
        visible: false,
        sortable: false,
      },
      {
        key: 'dtSolicitacao',
        title: 'Solicitacao',
        format: GridView.DataTypes.Date,
        sortable: false,
      },
      { key: 'noStatusDemanda', title: 'Status', sortable: false },
      { key: 'noIteracao', title: 'Iteração', sortable: false },
      {
        key: 'noObservacao',
        title: 'Observação',
        visible: false,
        sortable: false,
      },
      {
        key: 'nrSeqDemanda',
        title: 'Abrir',
        type: GridView.ColumnTypes.Button,
        onClick: (e, dataSource) => onOpenDemanda(e, dataSource),
        theme: Theme.Info,
        icon: 'folder-open',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Abrir Demanda',
        tooltipDirection: 'bottom',
      },
    ];

    const getDemandasDevolvidas = async (params = null) => {
      setLoading(true);

      const {
        data: demandas,
        pagination,
      } = await getDemandasDevolvidasPaginated(params);

      gridView?.current?.setDataSource(demandas ?? [], pagination);

      setLoading(false);
    };

    useEffect(() => {
      getDemandasDevolvidas();
    }, []);

    const onColumnSort = (sortBy) => {
      const filtros = { ...filters, sortBy };

      setFilters(filtros);

      getDemandasDevolvidas(filtros);
    };

    const onPageSizeChange = (totalByPage) => {
      const filtros = { ...filters, totalByPage };

      setFilters(filtros);

      getDemandasDevolvidas(filters);
    };

    const onPageChange = (page) => {
      const filtros = { ...filters, page };

      setFilters(filtros);

      getDemandasDevolvidas(filtros);
    };

    return (
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header title='Demandas Devolvidas' theme={Theme.Primary} />
            <Panel.Body>
              {/* <div className='row mb-3'>
              <div className='col'>
                <DatePicker
                  label='De'
                  text={filters.dtSolicitacaoInicial}
                  onChange={(dtInicial) => {
                    setFilters({ ...filters, dtSolicitacaoInicial: dtInicial });
                  }}
                />
              </div>
              <div className='col'>
                <DatePicker
                  label='Até'
                  text={filters.dtSolicitacaoFinal}
                  onChange={(dtFinal) => {
                    setFilters({ ...filters, dtSolicitacaoFinal: dtFinal });
                  }}
                />
              </div>
            </div> */}
              <div className='row'>
                <div className='col'>
                  <GridView
                    ref={gridView}
                    dataColumns={columns}
                    // showSelectSizes={false}
                    // showPagination={false}
                    onColumnSort={onColumnSort}
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onPageChange}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    );
  }
);

export default DemandaStep;
